import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["zipCode", "prefecture", "address"]

  initialize() {
    this.postalCodeValueChanged = this.postalCodeValueChanged.bind(this)
  }

  connect() {
    this.zipCodeTarget.addEventListener("input", this.postalCodeValueChanged)
  }

  disconnect() {
    this.zipCodeTarget.removeEventListener("input", this.postalCodeValueChanged)
  }

  postalCodeValueChanged() {
    const zipCode = this.zipCodeTarget.value
    if (zipCode.length === 7) {
      fetch(`https://api.zipaddress.net/?zipcode=${zipCode}`, {headers: {"Accept": "application/json"}})
        .then(response => response.json())
        .then(data => {
          if (data.code === 200) {
            const prefectureElement = document.querySelector(".js-prefecture")
            if (prefectureElement) {
              prefectureElement.value = data.data.pref
            }
            const addressElement = document.querySelector(".js-address")
            if (addressElement) {
              addressElement.value = data.data.address
            }
            addressElement.focus()
          }
        })
    }
  }
}
