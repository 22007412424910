import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["button", "content", "wrapper"];

  toggle() {
    const favoriteUrl = this.buttonTarget.dataset.favoriteUrl;
    const favorited = this.buttonTarget.dataset.favorited === "true";
    const before = this.buttonTarget.dataset.favoriteBefore;
    const after = this.buttonTarget.dataset.favoriteAfter;

    fetch(favoriteUrl, {
      method: favorited ? "DELETE" : "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.getElementsByName("csrf-token")[0].getAttribute("content"),
      },
    })
      .then((data) => {
        const content = this.contentTarget;
        content.innerHTML = favorited ? before : after;
        const wrapper = this.wrapperTarget;
        if (favorited) {
          wrapper.classList.remove("prj-btn-list__item--added");
          wrapper.classList.add("prj-btn-list__item--favorite");
        } else {
          wrapper.classList.remove("prj-btn-list__item--favorite");
          wrapper.classList.add("prj-btn-list__item--added");
        }
        this.buttonTarget.dataset.favorited = favorited ? "false" : "true";
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
}
