import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["numberField"];

  inputControl(event) {
    const input = event.target.value;
    event.target.value = input.replace(/[^\d]/g, "");
  }

  initialize() {
    this.numberFieldTarget.addEventListener("input", this.inputControl.bind(this));
  }
}
