import Swiper from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import imagesLoaded from 'imagesloaded';

export function initializeSwiper() {
  const swiperDiv = document.querySelector('div.swiper')
  if (!swiperDiv) {
    return;
  }

  console.log('initializeSwiper')

  imagesLoaded(document.querySelectorAll('.main-visual.swiper'), function (instance) {
    const createSwiper = function () {
      console.log('-- createSwiper')
      Swiper.use([Autoplay, Pagination]);
      // Swiperの設定
      new Swiper('.swiper', {
        loop: true,
        speed: 2000,
        autoplay: {
          delay: 2000,
        },
        pagination: {
          el: '.swiper-pagination',
          type:"bullets",
          clickable: true,
        },
        slidesPerView: 1,
        breakpoints: {
          500: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
        },
      });

      // ローダーの削除
      removeLoader();
    };

    const removeLoader = function () {
      console.log('-- removeLoader')
      const loader = document.querySelector('.loader');
      loader && loader.parentNode.removeChild(loader);

      // メインビジュアルの表示アニメーション
      showMainVisual();
    };

    const showMainVisual = function () {
      console.log('-- showMainVisual')
      const ms = 0;
      const mainVisualContent = document.querySelector('.main-visual-contents');
      const asideTopics = document.querySelector('.aside-topics');
      mainVisualContent.style.transition = "opacity " + ms + "ms";
      asideTopics.style.transition = "opacity " + ms + "ms";
      setTimeout(function () {
        mainVisualContent.style.opacity = 1;
        asideTopics.style.opacity = 1;
      }, 1);
    };

    createSwiper();
  });
}
