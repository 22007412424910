// form-init.js
export function initializeForm() {
  console.log('initializeForm')
  const contractOutput = function () {
    const select = document.querySelector("select[name='jibun.contract_type_number']");
    if (select) {
        select.addEventListener("change", function () {
            const contact = select.value;
            const display = {
                1 : '<p>・価格タイプ：一般価格<br>・契約期間（日数）：90日間<br>・金額：¥165,000</p>',
                2 : '<p>・価格タイプ：一般価格<br>・契約期間（日数）：365日間<br>・金額：¥440,000</p>',
                3 : '<p>・価格タイプ：スタートアップ価格<br>・契約期間（日数）：90日間<br>・金額：¥33,000</p>',
                4 : '<p>・価格タイプ：スタートアップ価格<br>・契約期間（日数）：365日間<br>・金額：¥132,000</p>',
            };
            const inputHidden = {
                1 : '<input type="hidden" value="一般価格" name="jibun.type" id="type"><input type="hidden" value="90" name="jibun.contract_period_day" id="contract_period_day"><input type="hidden" value="165000" name="jibun.price" id="price">',
                2 : '<input type="hidden" value="一般価格" name="jibun.type" id="type"><input type="hidden" value="365" name="jibun.contract_period_day" id="contract_period_day"><input type="hidden" value="440000" name="jibun.price" id="price">',
                3 : '<input type="hidden" value="スタートアップ価格" name="jibun.type" id="type"><input type="hidden" value="90" name="jibun.contract_period_day" id="contract_period_day"><input type="hidden" value="33000" name="jibun.price" id="price">',
                4 : '<input type="hidden" value="スタートアップ価格" name="jibun.type" id="type"><input type="hidden" value="365" name="jibun.contract_period_day" id="contract_period_day"><input type="hidden" value="132000" name="jibun.price" id="price">',
            };
            if (Object.keys(display).indexOf(contact) < 0){
                document.querySelector("#contract_confirm").innerHTML = "";
                document.querySelector("#contract_input_hidden").innerHTML = "";
            } else {
                document.querySelector("#contract_confirm").innerHTML = display[contact];
                document.querySelector("#contract_input_hidden").innerHTML = inputHidden[contact];
            }
        });
    }
};
contractOutput();

const validationForm = function () {
    'use strict';
    const forms = document.querySelectorAll('.needs-validation');
    Array.prototype.slice.call(forms)
    .forEach(function (form) {
        form.addEventListener('submit', function (event) {
            if (!form.checkValidity()) {
                event.preventDefault();
                event.stopPropagation();
                const errorElements = document.querySelectorAll(".form-control:invalid, .form-check-input:invalid, .form-select:invalid");
                for (let index = 0; index < errorElements.length; index++) {
                    const element = errorElements;
                    const rect = errorElements[0].getBoundingClientRect();
                    const position = rect.top - 105;
                    scrollBy(0, position);
                }
            }
        form.classList.add('was-validated');
        }, false)
    })
};
validationForm();

}
