import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["button", "content"];

  toggle() {
    const favoriteUrl = this.buttonTarget.dataset.favoriteUrl;
    const favorited = this.buttonTarget.classList.contains("favorited");
    const before = this.buttonTarget.dataset.favoriteBefore;
    const after = this.buttonTarget.dataset.favoriteAfter;

    fetch(favoriteUrl, {
      method: favorited ? "DELETE" : "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.getElementsByName("csrf-token")[0].getAttribute("content"),
      },
    })
      .then((data) => {
        const content = this.contentTarget;
        content.innerHTML = favorited ?
          `<i class="bi bi-heart"></i> ${before}` :
          `<i class="bi bi-heart-fill"></i> ${after}`;
        this.buttonTarget.classList.toggle("favorited");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
}
