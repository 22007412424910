// menu-init.js
export function initializeMenu() {
  console.log('initializeMenu')
  const toggleMenu = function (button) {
    const menuArea = button.nextElementSibling;
    const ms = 1500;
    menuArea.style.opacity = 0;
    menuArea.style.height = 0;
    menuArea.style.display = (menuArea.style.display === 'block') ? 'none' : 'block';
    menuArea.style.transition = "opacity " + ms + "ms";
    setTimeout(function () {
      menuArea.style.opacity = 1;
      menuArea.style.height = 'auto';
    }, 1);
    button.querySelector('.open-menu__btn').classList.toggle('open-menu__btn--active');
  };

  const openMenuButtons = document.querySelectorAll('.open-menu');
  Array.from(openMenuButtons).forEach(function (button) {
    button.addEventListener('click', function () {
      toggleMenu(this);
    });
  });

  const openMenuButtons_sv = document.querySelectorAll('.sv-wrap__menu');
  Array.from(openMenuButtons_sv).forEach(function (button) {
    button.addEventListener('click', function () {
      toggleMenu(this);
    });
  });
}

export function initializetoggleMenu() {
  console.log('initializetoggleMenu')
  const toggleMenuBox = function (button) {
    const menuArea = button.nextElementSibling;
    const ms = 1500;
    menuArea.style.display = (menuArea.style.display === 'none') ? 'block' : 'none';
    setTimeout(function () {
    }, 1);
    button.querySelector('.toggle-menu__btn').classList.toggle('toggle-menu__btn--close');
  };

  const openMenuButtons_toggle = document.querySelectorAll('.toggle-menu');
  Array.from(openMenuButtons_toggle).forEach(function (button) {
    button.addEventListener('click', function () {
      toggleMenuBox(this);
    });
  });
}