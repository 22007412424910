import {Application} from '@hotwired/stimulus'
import NestedForm from './custom_nested_form_controller'
import AddressAutofill from './address_autofill_controller'
import Modal from './modal_controller'
import Favorite from './favorite_controller'
import YosomonFavorite from './yosomon_favorite_controller'
import NumberOnly from './number_only_controller'

const application = Application.start()
application.register('nested-form', NestedForm)
application.register('address-autofill', AddressAutofill)
application.register('modal', Modal)
application.register('favorite', Favorite)
application.register('yosomon-favorite', YosomonFavorite)
application.register('number-only', NumberOnly)
