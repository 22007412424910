import NestedForm from 'stimulus-rails-nested-form'

export default class extends NestedForm {
  static values = {required: Boolean}

  connect() {
    super.connect()
    if (this.requiredValue) {
      this.updateRemoveButtonVisibility()
    }
  }

  updateRemoveButtonVisibility() {
    const removeButtons = this.element.querySelectorAll('[data-action="nested-form#remove"]')
    removeButtons.forEach((button) => {
      if (button === removeButtons[0]) {
        button.classList.add('d-none');
      } else {
        button.classList.remove('d-none');
      }
    })
  }
}
